.Header {
  height: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: center;
}

.Header-logo {
  height: 100%;
}

.Header-logo-container {
  height: 100%;
  margin: 0 40px;
}

.Header-logo-container:hover {
  opacity: 1;
}

.Header-tab {
  color: var(--color-black);
  margin: 0px 12px;
}

.Header-tab:hover {
  color: var(--color-black);
  opacity: var(--lighter-opacity);
  text-decoration: none;
}

.Header-tab--active, .Header-tab--active:hover {
  color: var(--color-primary);
  opacity: 1;
  border-bottom: 2px solid;
  padding-bottom: 4px;
}