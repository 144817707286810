.Tags {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.Tag {
  margin: 4px;
  padding: 2px 12px;
  background: var(--color-primary);
  color: var(--color-white);
  border-radius: 16px;
}
