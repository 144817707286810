.ProjectDetails {
  width: 100%;
  height: 100%;
}

.ProjectDetails-cover {
  position: relative;
  width: 100%;
  height: 400px;
}

.ProjectDetails-image {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}

.ProjectDetails-image-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(0,0,0,0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ProjectDetails-title {
  text-transform: uppercase;
  text-align: center;
  color: var(--color-white);
  margin-bottom: 0;
}

.ProjectDetails-subtitle {
  font-weight: var(--font-weight-light);
  color: var(--color-grey-light);
}

.ProjectDetails-back-button {
  padding: 16px;
  margin: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  color: var(--color-white);
}

.ProjectDetails-back-button:hover {
  opacity: var(--lighter-opacity);
}

.ProjectDetails-back-button-icon {
  height: 24px !important;
  width: 24px !important;
}

.ProjectDetails-content {
  padding: var(--main-padding);
  padding-left: var( --app-padding);
  padding-right: var( --app-padding);
}

.ProjectDetails-description-text, .Project-Details-component-name  {
  text-align: justify;
  line-height: 1.75rem;
}

.Project-Details-component-name {
  margin-bottom: 0.5rem;
}

.Project-Details-component-details {
  color: var(--color-black);
  font-weight: var(--font-weight-normal);
}

.ProjectDetails-description-header,
.ProjectDetails-tags-header {
  width: fit-content;
  border-bottom: 2px solid var(--color-black);
  text-align: center;
  margin: 0 auto var(--home-section-title-margin-bottom) auto
}

.ProjectDetails-tags {
  margin: var(--home-section-margin);
}