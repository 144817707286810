:root {
  /* Colors */
  --color-primary: #e74c3c;
  --color-primary-dark: #bf2718;
  --color-black: #2e394d;
  --color-black-dark: #11161d;
  --color-grey-lighter: #f5f5f5;
  --color-grey-light: #efefef;
  --color-grey: #93a0ab;
  --color-grey-dark: #6a7a88;
  --color-white: #fff;

  /* Font Family */
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --font-family-base: var(--font-family-sans-serif);

  /* Fonts sizes and weights*/
  --font-size-base: 1rem; /* Assumes the browser default, typically `16px` */

  --font-weight-lighter: lighter;
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-bold: 700;
  --font-weight-bolder: bolder;

  --font-weight-base: var(--font-weight-normal);
  --line-height-base: 1.5;

  /* Styles for the body element */

  --body-color: var(--color-black);
  --body-bg: var(--color-white);
  
  /* Styles for heading elements */
  
  --headings-margin-bottom: 0.5rem;

  /* Style anchor elements */
  
  --link-color: var(--color-primary);
  --link-decoration: none;
  --link-hover-color: var(--color-primary-dark);
  --link-hover-decoration: none;

  /* Style p elements */

  --paragraph-margin-bottom: 1rem;

  /* Other */
  --app-padding: 40px;
  --main-padding: 80px 0;
  --header-height: 80px;
  --default-border-radius: 2px;
  --modal-header-height: var(--header-height);
  --lighter-opacity: 0.8;
  --home-section-margin: var(--main-padding);
  --home-section-title-margin-bottom: 60px;
}
