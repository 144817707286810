.Job {
  margin-bottom: 48px;
}

.Job-company {
  color: var(--color-primary);
  width: fit-content;
  display: block;
  margin-bottom: 0.5rem;
}

.Job-dates {
  color: var(--color-grey);
  font-weight: var(--font-weight-light);
  margin-bottom: 0.75rem;
}

.Job-description {
  line-height: 1.75;
  text-align: justify;
}

.Job-read-more-button {
  padding: 4px 8px;
  margin-left: -8px;
  color: var(--color-primary);
  background: var(--color-white);
  transition: 0.1s all ease-in-out;
}

.Job-read-more-button:hover {
  color: var(--color-white);
  background: var(--color-primary);
  border-radius: var(--default-border-radius);
}