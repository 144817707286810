.Footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Footer-social-icons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5;
}

.Footer-social-icon-link {
  padding: 8px;
  margin: 8px;
}

.Footer-social-icon {
  color: var(--color-black);
  width: 24px !important;
  height: 24px !important;
}

.Footer-social-icon:hover {
  opacity: var(--lighter-opacity);
}

.Footer-copyright {
  color: var(--color-grey);
  margin-bottom: 0;
}
