.Projects {
  display: flex;
  flex-direction: column;
  margin: 0;
  flex-wrap: wrap;
}

.Project {
  margin: 16px 0;
  width: 100%;
  transition: all .3s;
  cursor: pointer;
}

.Project:hover {
  box-shadow: 0 0 8px var(--color-grey-lighter);
  transform: scale(1.01);
  opacity: 1;
}

.Project-image {
  width: 100%;
  border-radius: var(--default-border-radius) var(--default-border-radius) 0 0;
}

.Project-info {
  border: 1px solid var(--color-grey-light);
  border-top: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  border-radius: 0 0 var(--default-border-radius) var(--default-border-radius);
}

.Project-name {
  color: var(--color-black);
  text-align: center;
  margin-top: 0.25rem;
}

.Project-summary {
  font-weight: var(--font-weight-light);
  color: var(--color-grey);
  margin-bottom: 0.75rem;
  text-align: center;
}

@media (min-width: 576px) {
  .Projects {
    flex-direction: row;
    margin: -16px;
  }

  .Project {
    margin: 16px;
    width: calc(50% - 48px);
  }
}