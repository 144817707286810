.Layout {
  height: 100%;
  padding: 40px var(--app-padding);
}

.Layout-content {
  padding: var(--main-padding);
}

@media (min-width: 576px) {
  .Layout {
    padding: 80px var(--app-padding);
  }
}