@import './assets/css/variables.css';
@import './assets/css/bootrsap-reboot.css';

button {
  background: transparent;
  border: none;
}

button:hover,
button:focus {
  outline: none;
}
